<template>
  <el-row class="rowstyle">
    <h1 class="font-bold-32" v-if="!showprod">{{ overviewName }}</h1>
    <h1 class="font-bold-32" v-if="showprod">Products &#38; Services</h1>
    <el-breadcrumb
      style="background: unset; padding: 0.5em 0 0 0; margin: unset"
    >
      <el-breadcrumb-item active @click="viewOverview">
        <h5
          :class="showDetails ? 'flowstyle' : 'font-regular-20'"
          v-if="!showprod"
        >
          {{ overviewName }} Overview
        </h5>
        <h5
          :class="showDetails ? 'flowstyle' : 'font-regular-20'"
          v-if="showprod"
        >
          Overview
        </h5>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="showDetails" active to="#">
        <h5 class="font-regular-20">{{ detailName }} detail</h5>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </el-row>
</template>

<style lang="scss">
.rowstyle {
  flex-direction: column;
  text-align: start;
  padding: 1% 0 0 0;
}
a {
  color: #f2af00;
}
.flowstyle {
  color: #f2af00;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 22.24px;
  margin: 1rem 0;
  cursor: pointer;
}
.breadcrumb-item.active {
  display: flex;
}
a:hover {
  color: #f2af00;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "DetailsFlow",
  props: {
    overview: String,
    detail: String,
  },
  computed: {
    ...mapGetters([
      "getSubscriptionData",
      "getUserData",
      "getProductData",
      "getCompanyData",
    ]),
    showDetails(): any {
      if (this.detailName === "Product") {
        return !_.isEmpty(this.getProductData);
        // eslint-disable-next-line no-else-return
      } else if (this.detailName === "Subscription") {
        return !_.isEmpty(this.getSubscriptionData);
      } else if (this.detailName === "Company") {
        return !_.isEmpty(this.getCompanyData);
      } else return !_.isEmpty(this.getUserData);
    },
  },
  data() {
    return {
      overviewName: this.overview,
      detailName: this.detail,
      showprod: !(this.overview === "Users" || this.overview === "Companies"),
    };
  },
  methods: {
    viewOverview() {
      if (this.showDetails) this.$router.go(0);
    },
  },
});
</script>
