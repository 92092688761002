<template>
  <div class="companycontroltext">
    <div class="utils">
      <el-input
        id="companysearch"
        v-model="companySearchVal"
        placeholder="Search"
        type="search"
        class="rightmargin inpstyle"
        v-if="showOptions"
      >
        <template #suffix>
          <img src="@/assets/icons/search.svg" style="width: 25px" />
        </template>
      </el-input>
    </div>
  </div>
  <el-table
    id="companiestable"
    v-loading="loading"
    :data="companyList"
    style="width: 100%"
    max-height="600px"
    scrollbar-always-on
    :header-cell-style="headerstyle"
    @row-click="rowClickHandler"
    @sort-change="handleSorting"
    :row-style="rowdatastyle"
    :default-sort="{ prop: 'name', order: 'aescending' }"
    :empty-text="nodatatxt"
  >
    <el-table-column label="Company Name" prop="companyName" sortable>
      <template #default="company">
        <div :class="company.row.expirationCount > 0 ? 'expirationlabel' : ''">
          {{ company.row.companyName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="License"
      align="center"
      prop="licenseCount"
      sortable
    >
      <template #default="props">
        <div v-if="props.row.licenseCount === 0">&#8211;</div>
        <div v-if="props.row.licenseCount !== 0">
          {{ props.row.licenseCount }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="Subscription"
      align="center"
      prop="subscriptionCount"
      sortable
    >
      <template #default="props">
        <div v-if="props.row.subscriptionCount === 0">&#8211;</div>
        <div v-if="props.row.subscriptionCount !== 0">
          {{ props.row.subscriptionCount }}
        </div>
      </template>
    </el-table-column>
    <!-- <el-table-column align="center" prop="expirationCount" sortable>
      <template v-slot:header>
        <label>Expiration &nbsp; </label>
        <label class="columnlabel"> (in 30 days)</label>
      </template>
      <template #default="props">
        <div v-if="props.row.expirationCount === 0">&#8211;</div>
        <div
          :class="props.row.expirationCount !== 0 ? 'expirationlabel' : ''"
          v-if="props.row.expirationCount !== 0"
        >
          {{ props.row.expirationCount }}
        </div>
      </template>
    </el-table-column> -->
    <el-table-column fixed="right" label="" width="120">
      <template #default="company">
        <el-button @click="viewDetails(company)" plain link size="small">
          <img src="@/assets/icons/openlistdetails.svg" style="width: 25px" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagingdiv" v-if="totalPages > 1">
    <label class="pagelabel"
      ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
    >
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = 1"
      id="firstpage"
      :disabled="currentPage === 1"
    >
      <img
        src="@/assets/icons/pagefirstenabled.svg"
        style="width: 20px"
        v-if="currentPage !== 1"
      />
      <img
        src="@/assets/icons/pagefirstdisabled.svg"
        style="width: 20px"
        v-if="currentPage === 1"
      />
    </el-button>
    <el-pagination
      layout="prev, next"
      :total="totalRowsCount"
      :page-size="10"
      v-model:current-page="currentPage"
    />
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = totalPages"
      id="lastpage"
      :disabled="currentPage === totalPages"
    >
      <img
        src="@/assets/icons/pagelastenabled.svg"
        style="width: 20px"
        v-if="currentPage !== totalPages"
      />
      <img
        src="@/assets/icons/pagelastdisabled.svg"
        style="width: 20px"
        v-if="currentPage === totalPages"
      />
    </el-button>
  </div>
</template>
<style lang="scss">
.pagelabel {
  font-family: "Open Sans";
  margin: 10px 1rem;
}

.columnlabel {
  font-family: "Open Sans";
  font-weight: 300;
}

.companycontroltext {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pagingdiv {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.utils {
  display: flex;
  flex-direction: row;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #f2af00;
  border-color: #f2af00;
  font-family: "Open Sans";
  font-weight: 700;
  display: none;
}
.marginright {
  margin-right: 0.5rem;
}
.headerFont {
  font-size: 1.5em;
  line-height: 1.73em;
}

.expirationlabel {
  color: #df444d;
  font-size: 14px;
  line-height: 1.53em;
  font-family: "Open Sans";
  font-weight: 400;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import { mapGetters } from "vuex";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import _ from "lodash";

enum sorttingField {
  companyName = 11,
  licenseCount = 12,
  subscriptionCount = 13,
  expirationCount = 14,
}

export default defineComponent({
  name: "CompaniesOverview",
  components: {},
  computed: {
    ...mapGetters(["getCompanies", "getCurrentUser"]),
    companyList(): any {
      if (!_.isEmpty(this.getCompanies)) {
        this.updatePaginationData();
      } else if (!_.isEmpty(this.getCurrentUser)) {
        this.init();
      }
      return this.getCompanies.items;
    },
    currentRole(): string {
      return this.ctrltxt;
    },
    companySearchVal: {
      get(): string {
        return this.companySearchText;
      },
      set(val: string) {
        this.companySearchText = val;
      },
    },
    currentUserRole(): string {
      return !_.isEmpty(this.getCurrentUser)
        ? this.getCurrentUser.result.currentRole
        : "";
    },
    showOptions(): boolean {
      return !!(!_.isEmpty(this.getCompanies) || this.companySearchText !== "");
    },
  },
  data() {
    return {
      ctrltxt: "Available Users",
      nodatatxt: "No companies available",
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
        cursor: "pointer",
      },
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
      itemsOnPage: 0,
      companySearchText: "",
      loading: true,
      selected: [],
      sorttingField: null || 0,
      sortOrder: null || 0,
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        const companyDataObj = {
          companyBucketIdList: null,
          searchPhrase: this.companySearchText,
          userCurrentRole: this.currentUserRole,
          pageOptions: {
            skip: currPage - 1,
            take: 10,
            sortField: this.sorttingField,
            sortOrder: this.sortOrder,
          },
        };
        apiService.getCompanySearch(companyDataObj).then((res) => {
          this.loading = false;
          this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, res);
        });
        // if (this.companySearchText !== "") {

        // } else {
        //   apiService.getCompanySearch("", currPage - 1, 10).then((res) => {
        //     this.loading = false;
        //     this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, res);
        //   });
        // }
        this.selected = [];
      }
    },
    companySearchText(val) {
      const apiService = new APIService(this.axios);
      const companyDataObj = {
        companyBucketIdList: null,
        searchPhrase: this.companySearchText,
        userCurrentRole: this.currentUserRole,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: this.sorttingField,
          sortOrder: this.sortOrder,
        },
      };
      if (val.length !== 0) {
        this.checkSearchStr(companyDataObj);
      } else {
        apiService.getCompanySearch(companyDataObj).then((res) => {
          this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, res);
        });
      }
    },
  },
  methods: {
    checkSearchStr: _.debounce(function (this: any, companyDataObj: any) {
      const apiService = new APIService(this.axios);
      apiService.getCompanySearch(companyDataObj).then((res) => {
        this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, res);
      });
    }, 1000),
    init() {
      const companyDataObj = {
        companyBucketIdList: null,
        searchPhrase: this.companySearchText,
        userCurrentRole: this.currentUserRole,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: this.sorttingField,
          sortOrder: this.sortOrder,
        },
      };
      const apiService = new APIService(this.axios);
      apiService.getCompanySearch(companyDataObj).then((res) => {
        this.loading = false;
        this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, res);
      });
    },
    updatePaginationData() {
      this.currentPage =
        this.currentPage !== 0
          ? this.currentPage
          : this.getCompanies.pagingData.currentPage;
      this.totalPages = this.getCompanies.pagingData.totalPages;
      this.totalRowsCount = this.getCompanies.pagingData.itemCount;
    },
    viewDetails(company: any) {
      this.$store.dispatch(CompanyActionTypes.SET_COMPANY_DATA, company.row);
    },
    rowClickHandler(record: any) {
      this.$store.dispatch(CompanyActionTypes.SET_COMPANY_DATA, record);
    },
    handleSorting({ prop, order }: { prop: any; order: any }) {
      // eslint-disable-next-line no-nested-ternary
      this.sorttingField =
        prop === "companyName"
          ? sorttingField.companyName
          : prop === "licenseCount"
          ? sorttingField.licenseCount
          : prop === "subscriptionCount"
          ? sorttingField.subscriptionCount
          : sorttingField.expirationCount;
      this.sortOrder = order === "ascending" ? 0 : 1;
      const companyDataObj = {
        companyBucketIdList: null,
        searchPhrase: this.companySearchText,
        userCurrentRole: this.currentUserRole,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: this.sorttingField,
          sortOrder: this.sortOrder,
        },
      };
      this.loading = true;
      const apiService = new APIService(this.axios);
      apiService.getCompanySearch(companyDataObj).then((res) => {
        this.loading = false;
        this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, res);
      });
    },
  },
});
</script>
