<template>
  <h4 class="modal-title" id="assignaddondtitle">ASSIGN ADD-ON</h4>
  <div class="modalbody" v-if="taskType === 'assignaddon'">
    <div class="datadiv bottommargin">
      <label class="headerfont topmargin"> Select available add-on </label>
      <div class="overflowdiv topmargin">
        <el-table
          id="prodtoassigntable"
          v-loading="addonLoading"
          :data="addons"
          :border="true"
          style="width: 100%"
          :row-style="rowdatastyle"
          highlight-current-row
          @selection-change="selectedAddOn"
          :row-class-name="tableRowClassName"
          :show-header="false"
          empty-text="No assignable products available"
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column min-width="230">
            <template #default="props">
              <div class="prodnamediv">
                <img src="@/assets/icons/addonicon.svg" class="cellimage" />
                <div style="text-align: start">
                  <h6
                    class="font-bold"
                    style="font-size: 14px; line-height: 19.07px"
                  >
                    {{ props.row.productName }}
                  </h6>
                  <label>
                    Version: {{ props.row.majorVersionNumber }}.{{
                      props.row.minorVersionNumber
                    }}
                  </label>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="props">
              {{ props.row.seatsUsed }}&#47;{{ props.row.seats }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="buttonsdiv">
      <el-button
        id="assignprodnext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextHandler"
        :disabled="!showNext"
      >
        Next
      </el-button>
      <el-button
        id="prodassigncancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>
  <div v-if="taskType === 'summary'" class="modalbody">
    <SummaryViewModal :selectedAddon="selectedAddons" dataof="addon" />
    <div v-if="showError" style="display: flex" class="maxitems">
      <img
        src="@/assets/icons/warnerror.svg"
        class="cellimage"
        style="margin-top: 10px"
      />
      <label
        class="errordiv"
        v-if="addUserError !== ''"
        style="padding: 1rem 0"
      >
        {{ addUserError }}</label
      >
    </div>
    <div class="buttonsdiv">
      <el-button
        round
        class="btnstyle ml-4"
        variant="warning"
        id="prodassingconfirm"
        @click="sendInvite"
        :disabled="!enableControls"
      >
        <i v-if="!enableControls" class="fa fa-refresh fa-spin"></i> Confirm
      </el-button>
      <el-button
        id="prodassigncancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>
  <div v-if="taskType === 'emailSent'" class="modalbody">
    <div class="innerModal">
      <img
        src="@/assets/icons/mailsent.svg"
        style="width: 70px; margin-bottom: 1rem"
      />
      <label class="labelbld">Notification email sent to: </label>
      <div class="usersdiv">
        <div
          v-for="(item, index) in usersSelected"
          :key="index"
          style="display: flex"
          class="btm-1"
        >
          <p>
            {{ item.name }}<br />
            &#40;{{ item.email }} &#41;
          </p>
        </div>
      </div>
    </div>
    <div class="buttonsdiv">
      <el-button
        id="prodassigndone"
        round
        class="btnstyle"
        variant="warning"
        @click="cancelInvite"
        style="float: right"
      >
        Done</el-button
      >
    </div>
  </div>
</template>

<style lang="scss"></style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { ProductInfomation, UserInformation } from "@/utils/AppInterfaces";
// import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import SummaryViewModal from "./SummaryViewModal.vue";

export default defineComponent({
  name: "AssignAddonModal",
  components: {
    SummaryViewModal,
  },
  computed: {
    ...mapGetters([
      "getAvailableProducts",
      "getUserData",
      "getSelectedUsers",
      "getTaskType",
      "getCompanyData",
      "getCurrentUser",
      "getAddonsList",
      "getProductData",
      "getSubscriptionData",
    ]),
    addons(): any {
      if (this.getAddonsList.length === 0) this.getAvailableAddOns();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      else this.addonLoading = false;
      return this.getAddonsList;
    },
    selectedUsers(): any {
      return this.getSelectedUsers.length !== undefined
        ? this.getSelectedUsers
        : [this.getUserData];
    },
    taskType(): any {
      return this.getTaskType;
    },
    showNext(): any {
      return this.selectedAddons.length !== 0;
    },
  },
  data() {
    return {
      userdetails: this.userData,
      enableControls: true,
      currentRow: null as unknown as ProductInfomation,
      showError: false,
      addUserError: "",
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      productsLoaded: false,
      loading: true,
      productSelected: false,
      addonLoading: true,
      selectedAddons: [],
      usersSelected: [] as UserInformation[],
    };
  },
  methods: {
    tableRowClassName({ row }: { row: ProductInfomation }) {
      if (row.seats === row.seatsUsed) {
        return "disable-row";
      }
      return "";
    },
    getAvailableAddOns() {
      const mainProductInfomation = {
        mainProductBucketId: this.getSubscriptionData.productBucketId,
        mainProductMajorVersion: this.getSubscriptionData.majorVersionNumber,
        mainProductMinorVersion: this.getSubscriptionData.minorVersionNumber,
        companyBucketIdList: [this.getSelectedUsers[0].companyBucketId], //[this.getSubscriptionData.companyBucketId],  //Swathi todo This should use Product's compnaybucketId, but as of now using user's companybucketId as a workaround
      };

      const apiService = new APIService(this.axios);
      apiService
        .getSubscriptionAddonsList(mainProductInfomation)
        .then((res) => {
          this.$store.dispatch(ProductActionTypes.SET_ADDONS_LIST, res.items);
          this.productsLoaded = true;
          this.addonLoading = false;
        });
    },
    nextHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "summary");
    },
    selectedAddOn(selected: any) {
      // this.$store.dispatch(FlowActionTypes.SET_ADDON_DATA, selected);
      this.selectedAddons = selected;
    },
    sendInvite() {
      this.enableControls = false;
      const addOnsList: any[] = [];
      this.selectedAddons.map((item: any) => {
        addOnsList.push({
          productBucketId: item.productBucketId,
          major: item.majorVersionNumber,
          minor: item.minorVersionNumber,
          patch: item.patchVersionNumber,
        });
      });
      const apiService = new APIService(this.axios);
      const multipleRequestData: any[] = [];
      this.usersSelected = this.getSelectedUsers;
      this.selectedUsers.map((user: any) => {
        const inviteObj = {
          companyBucketId: this.getSubscriptionData.companyBucketId,
          userBucketId: user.userBucketId,
          entitlementsToAssign: addOnsList,
        };

        multipleRequestData.push(inviteObj);
      });
      apiService.assignMultipleSubscription(multipleRequestData).then((res) => {
        if (res.areAllSuccessfull) {
          const subData = {
            Searchphrase: "",
            productBucketId: this.getSubscriptionData.productBucketId,
            Major: this.getSubscriptionData.majorVersionNumber,
            Minor: this.getSubscriptionData.minorVersionNumber,
            Patch: this.getSubscriptionData.patchVersionNumber,
            pageOptions: {
              skip: 0,
              take: 10,
              sortField: null,
              sortOrder: null,
            },
          };
          this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "emailSent");
          apiService.getAssignedUsersSubList(subData).then((resp) => {
            const subInfo = {
              productBucketId: this.getSubscriptionData.productBucketId,
              majorVersionNumber: this.getSubscriptionData.majorVersionNumber,
              minorVersionNumber: this.getSubscriptionData.minorVersionNumber,
              patchVersionNumber: this.getSubscriptionData.patchVersionNumber,
            };
            apiService.getProduct(subInfo).then((prod) => {
              this.$store.dispatch(
                FlowActionTypes.SET_SUBSCRIPTION_DATA,
                prod.items[0]
              );
            });
            this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, resp);
          });
          // apiService
          //   .getAssignedProdsList(this.getUserData.userBucketId, "Ascending")
          //   .then((response) => {
          //     this.$store.dispatch(
          //       UserActionTypes.SET_ASSIGNED_PRODUCTS,
          //       response
          //     );
          //   });
          // apiService.getProductList().then((resp) => {
          //   this.$store.dispatch(ProductActionTypes.SET_PRODUCTS, resp);
          // });
        } else {
          this.showError = true;
          let errormessage = "";
          errormessage = res.validationResult.validationMessages.join(" ");
          this.showError = true;
          this.addUserError = errormessage;
        }
      });
      return !this.enableControls;
      // this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "emailSent");
      // return !this.enableControls;
    },
    cancelInvite() {
      this.usersSelected = [];
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      this.$store.dispatch(UserActionTypes.SET_USER_ADDONS, []);
      this.$store.dispatch(ProductActionTypes.SET_ADDONS_LIST, []);
      // this.$store.dispatch(FlowActionTypes.SET_ADDON_DATA, []);
    },
  },
  // mounted() {},
  props: {
    showheading: Boolean,
    userData: null,
  },
});
</script>
