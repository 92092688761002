<template>
  <h4 class="modal-title" id="assignaddontitle">REMOVE ADD-ONS</h4>
  <div class="modalbody" v-if="!removeSuccess">
    <div class="datadiv bottommargin">
      <label class="headerfont"> Select add-ons to remove </label>
      <div class="overflowdiv topmargin">
        <el-table
          id="prodtoassigntable"
          :data="userAddonsList"
          :border="true"
          style="width: 100%"
          :row-style="rowdatastyle"
          @selection-change="handleCurrentChange"
          :show-header="false"
          empty-text="No add-ons to remove"
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column min-width="230">
            <template #default="props">
              <div class="prodnamediv">
                <div style="text-align: start">
                  <h6
                    class="font-bold"
                    style="font-size: 14px; line-height: 19.07px"
                  >
                    {{ props.row.productName }}
                  </h6>
                  <label>
                    Version: {{ props.row.majorVersionNumber }}.{{
                      props.row.minorVersionNumber
                    }}
                  </label>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>

  <div class="buttonsdiv" v-if="!removeSuccess">
    <el-button
      id="assignaddonnext"
      round
      class="btnstyle ml-4"
      variant="warning"
      @click="removeSelectedAddons"
      :disabled="!enableNext"
    >
      <i v-if="!enableNext && !showError" class="fa fa-refresh fa-spin"></i>
      Continue
    </el-button>
    <el-button
      id="prodassigncancel"
      round
      variant="outline-dark"
      @click="cancelRemoval"
      class="cancelbtn"
      >Cancel</el-button
    >
  </div>

  <div v-if="removeSuccess" class="modalbody">
    <div class="font-bold-16" v-if="removeSuccess">
      Selected Add-Ons have been removed successfully.
    </div>
    <div class="font-bold-16" v-if="showError">
      Error occured during removal of add-ons.
    </div>
    <!-- <div v-if="showError" style="display: flex" class="maxitems">
      <img
        src="@/assets/icons/warnerror.svg"
        class="cellimage"
        style="margin-top: 10px"
      />
      <label
        class="errordiv"
        v-if="addUserError !== ''"
        style="padding: 1rem 0"
      >
        {{ addUserError }}</label
      >
    </div> -->
    <div class="buttonsdiv">
      <el-button
        round
        class="btnstyle ml-4"
        variant="warning"
        id="prodassingconfirm"
        @click="cancelRemoval"
      >
        Done
      </el-button>
    </div>
  </div>
</template>

<style lang="scss"></style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";

export default defineComponent({
  name: "RemoveAddonsModal",
  components: {},
  computed: {
    ...mapGetters([
      "getUserAddons",
      "getAddonProducts",
      "getSelectedUserids",
      "getSubscriptionData",
      "getUserData",
    ]),
    userAddonsList(): any {
      return this.getAddonProducts.length === 0
        ? this.getUserAddons
        : this.getAddonProducts;
    },
    selectedUsers(): any {
      return this.getSelectedUserids !== undefined &&
        this.getSelectedUserids.length !== 0
        ? this.getSelectedUserids
        : [this.getUserData.userBucketId];
    },
  },
  data() {
    return {
      headerstyle: {
        "border-bottom": "unset",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      selectedAddonsList: [] as any[],
      enableNext: false,
      removalDone: false,
      removeSuccess: false,
      showError: false,
    };
  },
  methods: {
    handleCurrentChange(selected: any[]) {
      this.selectedAddonsList = selected;
      if (this.selectedAddonsList.length !== 0) this.enableNext = true;
    },
    removeSelectedAddons() {
      const apiService = new APIService(this.axios);
      const addonsToRemove = [] as any[];
      const multipleRequestData = [] as any[];
      this.selectedAddonsList.map((addon) =>
        addonsToRemove.push({
          productBucketId: addon.productBucketId,
          major: addon.majorVersionNumber,
          minor: addon.minorVersionNumber,
          patch: addon.patchVersionNumber,
        })
      );
      this.selectedUsers.map((userid: string) => {
        const removeRequest = {
          userBucketId: userid,
          entitlementsToRemove: addonsToRemove,
        } as any;
        multipleRequestData.push(removeRequest);
      });

      console.log(multipleRequestData);

      apiService
        .removeMultipleSubscriptions(multipleRequestData)
        .then((res) => {
          if (res.areAllSuccessfull) {
            if (this.$route.name === "user") {
              const userDataObj = {
                userBucketIdList: null,
                companyBucketIdList: null,
                searchPhrase: null,
                companyName: null,
                pageOptions: {
                  skip: 0,
                  take: 10,
                  sortField: null,
                  sortOrder: null,
                },
              };

              apiService.getUsersList(userDataObj).then((resp) => {
                this.$store.dispatch(UserActionTypes.SET_USERS, resp);
              });
              apiService
                .getAssignedProdsList(
                  this.getUserData.userBucketId,
                  "Ascending"
                )
                .then((response) => {
                  this.$store.dispatch(
                    UserActionTypes.SET_ASSIGNED_PRODUCTS,
                    response
                  );
                });
            } else {
              const subData = {
                Searchphrase: "",
                productBucketId: this.getSubscriptionData.productBucketId,
                Major: this.getSubscriptionData.majorVersionNumber,
                Minor: this.getSubscriptionData.minorVersionNumber,
                Patch: this.getSubscriptionData.patchVersionNumber,
                pageOptions: {
                  skip: 0,
                  take: 10,
                  sortField: null,
                  sortOrder: null,
                },
              };
              const subInfo = {
                productBucketId: this.getSubscriptionData.productBucketId,
                majorVersionNumber: this.getSubscriptionData.majorVersionNumber,
                minorVersionNumber: this.getSubscriptionData.minorVersionNumber,
                patchVersionNumber: this.getSubscriptionData.patchVersionNumber,
              };
              apiService.getProduct(subInfo).then((prod) => {
                this.$store.dispatch(
                  FlowActionTypes.SET_SUBSCRIPTION_DATA,
                  prod.items[0]
                );
              });
              apiService.getAssignedUsersSubList(subData).then((resp) => {
                this.$store.dispatch(
                  ProductActionTypes.SET_ASSIGNED_USERS,
                  resp
                );
              });
            }

            this.removalDone = true;
            this.removeSuccess = true;
          } else {
            this.removalDone = true;
            this.showError = true;
            // ElMessage.error(res.validationResult.validationMessages[0]);
          }
        });
    },
    cancelRemoval() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, false);
      this.$store.dispatch(FlowActionTypes.SET_ADDON_USERS, []);
      this.$store.dispatch(UserActionTypes.SET_USER_ADDONS, []);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERIDS, []);
      this.$store.dispatch(ProductActionTypes.SET_ADDONS_LIST, []);
    },
  },
});
</script>
