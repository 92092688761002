<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <HeaderComponent v-if="userAuthenticated" />
      </el-header>
      <el-row class="envClass" v-if="envName !== ''"
        >Environment : {{ envName }}
      </el-row>
      <el-main>
        <ErrorMessage v-if="userError" />
        <router-view v-if="userAuthenticated && !userError" />
      </el-main>
    </el-container>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import { mapGetters } from "vuex";
//import SSOAuth from "@/utils/SSOAuth";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import _ from "lodash";

export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    ErrorMessage,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getErrorOccured", "getCurrentUser", "getUserError"]),
    envName(): string {
      return !_.isEmpty(this.getCurrentUser) &&
        this.getCurrentUser.result.environmentName !== ""
        ? this.getCurrentUser.result.environmentName
        : "";
    },
    userAuthenticated(): boolean {
      return useIsAuthenticated().value;
    },
    userError(): any {
      return this.getUserError;
    },
  },
  methods: {
    processLogin() {
      this.$store.dispatch(AuthActionTypes.LOGIN);
      if (this.$route.name === "home" || this.$route.name === "auth")
        this.$router.push("/account");
    },
  },
  created() {
    if (useIsAuthenticated().value) {
      this.processLogin();
    }
  },
});
</script>

<style lang="scss">
#app {
  font-family: "Open Sans", sans-serif, Avenir, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background: #f2f2f2;
}

body {
  margin: 0;
  color: #333333;
  border: 1px solid #d6d8da;
  height: 100vh;
  background-color: #f2f2f2;
  font-family: "Open Sans", sans-serif;
}
</style>
