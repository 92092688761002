import {
  MutationTree,
  ActionTree,
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
} from "vuex";
import { FlowMutationTypes } from "./FlowMutationTypes";
import { FlowActionTypes } from "./FlowActionTypes";
import { State, state } from "../AppState";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [FlowActionTypes.SET_SHOWHEADER_SIDEBAR](
    context: AugmentedActionContext,
    showuser: boolean
  ): Promise<boolean>;
  [FlowActionTypes.SET_SELECTED_USERIDS](
    context: AugmentedActionContext,
    userids: any
  ): Promise<any>;
  [FlowActionTypes.SET_CURRENT_PAGE](
    context: AugmentedActionContext,
    page: number
  ): Promise<number>;
  [FlowActionTypes.SET_SHOW_ADDUSER](
    context: AugmentedActionContext,
    showuser: boolean
  ): Promise<boolean>;
  [FlowActionTypes.SET_SELECTED_EMAILS](
    context: AugmentedActionContext,
    emails: []
  ): Promise<[]>;
  [FlowActionTypes.SET_SELECTED_PRODUCTS](
    context: AugmentedActionContext,
    products: any
  ): Promise<any>;
  [FlowActionTypes.SET_PRODUCT_DATA](
    context: AugmentedActionContext,
    prodata: any
  ): Promise<any>;
  [FlowActionTypes.SET_SUBSCRIPTION_DATA](
    context: AugmentedActionContext,
    subdata: any
  ): Promise<any>;
  [FlowActionTypes.SET_USER_DATA](
    context: AugmentedActionContext,
    userdata: any
  ): Promise<any>;
  [FlowActionTypes.SET_SHOW_ADDPRODUCTS](
    context: AugmentedActionContext,
    showuser: boolean
  ): Promise<boolean>;
  [FlowActionTypes.SET_TASK_TYPE](
    context: AugmentedActionContext,
    type: string
  ): Promise<string>;
  [FlowActionTypes.SET_SELECTED_USERS](
    context: AugmentedActionContext,
    users: any
  ): Promise<any>;
  [FlowActionTypes.SET_SEARCH_VALUE](
    context: AugmentedActionContext,
    val: string
  ): Promise<string>;
  [FlowActionTypes.SET_ADDON_DATA](
    context: AugmentedActionContext,
    data: any
  ): Promise<any>;
  [FlowActionTypes.SET_ADDON_USERS](
    context: AugmentedActionContext,
    data: any
  ): Promise<any>;
  [FlowActionTypes.SET_SHOW_ASSIGNADDON](
    context: AugmentedActionContext,
    showuser: boolean
  ): Promise<boolean>;
  [FlowActionTypes.SET_SHOW_REMOVEADDON](
    context: AugmentedActionContext,
    showuser: boolean
  ): Promise<boolean>;
}

export const actions: ActionTree<State, State> = {
  [FlowActionTypes.SET_SHOWHEADER_SIDEBAR](
    context: AugmentedActionContext,
    showuser: boolean
  ) {
    return context.commit(
      FlowMutationTypes.UPDATE_SHOWHEADER_SIDEBAR,
      showuser
    );
  },
  [FlowActionTypes.SET_SELECTED_USERIDS](
    context: AugmentedActionContext,
    showuser: boolean
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SELECTED_USERIDS, showuser);
  },
  [FlowActionTypes.SET_CURRENT_PAGE](
    context: AugmentedActionContext,
    page: number
  ) {
    return context.commit(FlowMutationTypes.UPDATE_CURRENT_PAGE, page);
  },
  [FlowActionTypes.SET_SHOW_ADDUSER](
    context: AugmentedActionContext,
    showuser: boolean
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SHOW_ADDUSER, showuser);
  },
  [FlowActionTypes.SET_SELECTED_EMAILS](
    context: AugmentedActionContext,
    emails: []
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SELECTED_EMAILS, emails);
  },
  [FlowActionTypes.SET_SELECTED_PRODUCTS](
    context: AugmentedActionContext,
    products: any
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SELECTED_PRODUCTS, products);
  },
  [FlowActionTypes.SET_PRODUCT_DATA](
    context: AugmentedActionContext,
    prodata: any
  ) {
    return context.commit(FlowMutationTypes.UPDATE_PRODUCT_DATA, prodata);
  },
  [FlowActionTypes.SET_USER_DATA](
    context: AugmentedActionContext,
    userdata: any
  ) {
    return context.commit(FlowMutationTypes.UPDATE_USER_DATA, userdata);
  },
  [FlowActionTypes.SET_SHOW_ADDPRODUCTS](
    context: AugmentedActionContext,
    show: boolean
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SHOW_ADDPRODUCTS, show);
  },
  [FlowActionTypes.SET_SUBSCRIPTION_DATA](
    context: AugmentedActionContext,
    subdata: any
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SUBSCRIPTION_DATA, subdata);
  },
  [FlowActionTypes.SET_TASK_TYPE](
    context: AugmentedActionContext,
    tasktype: string
  ) {
    return context.commit(FlowMutationTypes.UPDATE_TASK_TYPE, tasktype);
  },
  [FlowActionTypes.SET_SELECTED_USERS](
    context: AugmentedActionContext,
    selectedusers: any
  ) {
    return context.commit(
      FlowMutationTypes.UPDATE_SELECTED_USERS,
      selectedusers
    );
  },
  [FlowActionTypes.SET_SEARCH_VALUE](
    context: AugmentedActionContext,
    value: string
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SEARCH_VALUE, value);
  },
  [FlowActionTypes.SET_ADDON_DATA](context: AugmentedActionContext, data: any) {
    return context.commit(FlowMutationTypes.UPDATE_ADDON_DATA, data);
  },
  [FlowActionTypes.SET_ADDON_USERS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(FlowMutationTypes.UPDATE_ADDON_USERS, data);
  },
  [FlowActionTypes.SET_ADDON_PRODUCTS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(FlowMutationTypes.UPDATE_ADDON_PRODUCTS, data);
  },
  [FlowActionTypes.SET_SHOW_ASSIGNADDON](
    context: AugmentedActionContext,
    showuser: boolean
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SHOW_ASSIGNADDON, showuser);
  },
  [FlowActionTypes.SET_SHOW_REMOVEADDON](
    context: AugmentedActionContext,
    showuser: boolean
  ) {
    return context.commit(FlowMutationTypes.UPDATE_SHOW_REMOVEADDON, showuser);
  },
};

export type Mutations<S = State> = {
  [FlowMutationTypes.UPDATE_SHOWHEADER_SIDEBAR](state: S, show: boolean): void;
  [FlowMutationTypes.UPDATE_SELECTED_USERIDS](state: S, show: boolean): void;
  [FlowMutationTypes.UPDATE_CURRENT_PAGE](state: S, pageNumber: number): void;
  [FlowMutationTypes.UPDATE_SHOW_ADDUSER](state: S, show: boolean): void;
  [FlowMutationTypes.UPDATE_SELECTED_EMAILS](state: S, emails: []): void;
  [FlowMutationTypes.UPDATE_SELECTED_PRODUCTS](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_PRODUCT_DATA](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_USER_DATA](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_SHOW_ADDPRODUCTS](state: S, show: boolean): void;
  [FlowMutationTypes.UPDATE_SELECTED_USERS](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_SUBSCRIPTION_DATA](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_TASK_TYPE](state: S, type: string): void;
  [FlowMutationTypes.UPDATE_SEARCH_VALUE](state: S, val: string): void;
  [FlowMutationTypes.UPDATE_ADDON_DATA](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_ADDON_USERS](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_ADDON_PRODUCTS](state: S, data: any): void;
  [FlowMutationTypes.UPDATE_SHOW_ASSIGNADDON](state: S, show: boolean): void;
  [FlowMutationTypes.UPDATE_SHOW_REMOVEADDON](state: S, show: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FlowMutationTypes.UPDATE_SHOWHEADER_SIDEBAR](state, show: boolean) {
    state.showSideHeader = show;
  },
  [FlowMutationTypes.UPDATE_SELECTED_USERIDS](state, userIds: any) {
    state.selectedUserids = userIds;
  },
  [FlowMutationTypes.UPDATE_CURRENT_PAGE](state, pageNumber: number) {
    state.currentPage = pageNumber;
  },
  [FlowMutationTypes.UPDATE_SHOW_ADDUSER](state, show: boolean) {
    state.showAdduser = show;
  },
  [FlowMutationTypes.UPDATE_SELECTED_EMAILS](state, emails: []) {
    state.selectedEmails = emails;
  },
  [FlowMutationTypes.UPDATE_SELECTED_PRODUCTS](state, data: any) {
    state.selectedProducts = data;
  },
  [FlowMutationTypes.UPDATE_PRODUCT_DATA](state, data: any) {
    state.subscriptionData = {};
    state.productData = data;
  },
  [FlowMutationTypes.UPDATE_USER_DATA](state, data: any) {
    state.subscriptionData = {};
    state.userData = data;
  },
  [FlowMutationTypes.UPDATE_SHOW_ADDPRODUCTS](state, show: boolean) {
    state.showAddProducts = show;
  },
  [FlowMutationTypes.UPDATE_SELECTED_USERS](state, data: any) {
    state.selectedUsers = data;
  },
  [FlowMutationTypes.UPDATE_SUBSCRIPTION_DATA](state, data: any) {
    state.productData = {};
    state.subscriptionData = data;
  },
  [FlowMutationTypes.UPDATE_TASK_TYPE](state, type: string) {
    state.taskType = type;
  },
  [FlowMutationTypes.UPDATE_SEARCH_VALUE](state, value: string) {
    state.searchValue = value;
  },
  [FlowMutationTypes.UPDATE_ADDON_DATA](state, data: any) {
    state.addonData = data;
  },
  [FlowMutationTypes.UPDATE_ADDON_USERS](state, data: any) {
    state.addonUsers = data;
  },
  [FlowMutationTypes.UPDATE_ADDON_PRODUCTS](state, data: any) {
    state.addonProducts = data;
  },
  [FlowMutationTypes.UPDATE_SHOW_ASSIGNADDON](state, show: boolean) {
    state.showAssignAddon = show;
  },
  [FlowMutationTypes.UPDATE_SHOW_REMOVEADDON](state, show: boolean) {
    state.showRemoveAddon = show;
  },
};
export type Getters = {
  getShowAdduser(state: State): boolean;
  getShowSideHeader(state: State): boolean;
  getSlectedEmails(state: State): any;
  getSelectedProducts(state: State): any;
  getProductData(state: State): any;
  getShowAddProducts(state: State): boolean;
  getSubscriptionData(state: State): any;
  getTaskType(state: State): string;
  getSelectedUsers(state: State): any;
  getSelectedUserids(state: State): any;
  getCurrentPage(state: State): number;
  getSearchValue(state: State): string;
  getAddonData(state: State): any;
  getAddonUsers(state: State): any;
  getAddonProducts(state: State): any;
  getShowAssignAddon(state: State): boolean;
  getShowRemoveAddon(state: State): boolean;
};

export const getters: GetterTree<State, State> = {
  getShowAdduser: (state) => state.showAdduser,
  getShowSideHeader: (state) => state.showSideHeader,
  getSlectedEmails: (state) => state.selectedEmails,
  getSelectedProducts: (state) => state.selectedProducts,
  getProductData: (state) => state.productData,
  getUserData: (state) => state.userData,
  getShowAddProducts: (state) => state.showAddProducts,
  getSubscriptionData: (state) => state.subscriptionData,
  getTaskType: (state) => state.taskType,
  getSelectedUsers: (state) => state.selectedUsers,
  getSelectedUserids: (state) => state.selectedUserids,
  getCurrentPage: (state) => state.currentPage,
  getSearchValue: (state) => state.searchValue,
  getAddonData: (state) => state.addonData,
  getAddonUsers: (state) => state.addonUsers,
  getAddonProducts: (state) => state.addonProducts,
  getShowAssignAddon: (state) => state.showAssignAddon,
  getShowRemoveAddon: (state) => state.showRemoveAddon,
};

export type FlowStore<S = State> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const flowstore: Module<State, State> = {
  state,
  getters,
  mutations,
  actions,
};
