<template>
  <div class="companycontroltext">
    <div class="usersdivstyle">
      <el-button
        plain
        class="delbtn"
        @click="showDeleteDailogue = true"
        id="deletecompanyuser"
        :disabled="getSlectedEmails.length === 0"
      >
        <img
          src="@/assets/icons/delete.svg"
          style="width: 40px"
          v-if="getSlectedEmails.length !== 0"
        />
        <img
          src="@/assets/icons/deletedisabled.svg"
          style="width: 40px"
          v-if="getSlectedEmails.length === 0"
        />
      </el-button>
      <el-input
        id="companyusersearch"
        v-model="usrSearchVal"
        placeholder="Search"
        type="search"
        :debounce="1000"
        class="rightmargin inpstyle"
      >
        <template #suffix>
          <img src="@/assets/icons/search.svg" style="width: 25px" />
        </template>
      </el-input>
    </div>
  </div>

  <el-table
    id="companyuserstable"
    v-loading="loading"
    :data="companyUsersList"
    style="width: 100%"
    max-height="600px"
    scrollbar-always-on
    :header-cell-style="headerstyle"
    :header-row-style="headerrow"
    :row-style="rowdatastyle"
    @selection-change="handleSelectionChange"
    @sort-change="handleSorting"
    :default-sort="{ prop: 'name', order: 'aescending' }"
    empty-text="No users available"
  >
    <el-table-column type="selection" width="55" />
    <el-table-column label="User Name" prop="name" sortable />
    <el-table-column label="Email" prop="email" sortable />
    <el-table-column label="Assigned Products" sortable>
      <template #default="props">
        <div v-for="(prod, index) in props.row.products" :key="index">
          {{ prod }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Status" width="150" prop="userStatus" sortable>
      <template #default="user">
        <img
          src="@/assets/icons/active.svg"
          v-if="user.row.userStatus === 'Active'"
        />
        <img
          src="@/assets/icons/idle.svg"
          v-if="user.row.userStatus === 'Idle'"
        />
        <img
          src="@/assets/icons/inactive.svg"
          v-if="user.row.userStatus === 'Deleted'"
        />
        <label style="margin-left: 5px">{{ user.row.userStatus }}</label>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagingdiv" v-if="totalPages > 1">
    <label class="pagelabel"
      ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
    >
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = 1"
      id="firstpage"
      :disabled="currentPage === 1"
    >
      <img
        src="@/assets/icons/pagefirstenabled.svg"
        style="width: 20px"
        v-if="currentPage !== 1"
      />
      <img
        src="@/assets/icons/pagefirstdisabled.svg"
        style="width: 20px"
        v-if="currentPage === 1"
      />
    </el-button>
    <el-pagination
      layout="prev, next"
      :total="totalRowsCount"
      :page-size="10"
      v-model:current-page="currentPage"
    />
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = totalPages"
      id="lastpage"
      :disabled="currentPage === totalPages"
    >
      <img
        src="@/assets/icons/pagelastenabled.svg"
        style="width: 20px"
        v-if="currentPage !== totalPages"
      />
      <img
        src="@/assets/icons/pagelastdisabled.svg"
        style="width: 20px"
        v-if="currentPage === totalPages"
      />
    </el-button>
  </div>
  <el-dialog
    title="Delete Users"
    v-model="showDeleteDailogue"
    width="35%"
    top="25vh"
    center
  >
    <el-divider></el-divider>
    <div class="text-div">
      <span
        ><strong>{{ getSlectedEmails.length }}</strong> Users selected
      </span>
      <br />
      <span>All user data and subscriptions will be permanently removed.</span>
      <span>This action may not be undone.</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          id="deleteusercancel"
          @click="showDeleteDailogue = false"
          round
          class="cancelbtn"
          variant="outline-dark"
          >Cancel</el-button
        >
        <el-button
          type="danger"
          @click="deleteUser"
          round
          class="deletebutton"
          id="deleteuserconfirm"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<style lang="scss">
.expirationlabel {
  color: #df444d;
  font-size: 14px;
  line-height: 1.53em;
  font-family: "Open Sans";
  font-weight: 400;
}
.usersdivstyle {
  display: flex;
  flex-direction: row-reverse;
}

.comprowstyle {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.el-table--fit .el-table__cell.gutter {
  background: #f2f2f2;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import { mapGetters } from "vuex";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ElMessage } from "element-plus";
import _ from "lodash";

export default defineComponent({
  name: "CompanyUsers",
  computed: {
    ...mapGetters([
      "getCompanyUsers",
      "getCompanyData",
      "getSlectedEmails",
      "getSearchValue",
    ]),
    companyUsersList(): any {
      if (!_.isEmpty(this.getCompanyUsers)) {
        this.updatePaginationData();
      }
      return this.getCompanyUsers.items;
    },
    usrSearchVal: {
      get(): string {
        return this.getSearchValue === ""
          ? this.usrSearcher
          : this.getSearchValue;
      },
      set(val: string) {
        this.usrSearcher = val;
      },
    },
  },
  data() {
    return {
      loading: true,
      showDeleteDailogue: false,
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      headerrow: {
        background: "#F2F2F2",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
      itemsOnPage: 0,
      sorttingField: null || 0,
      sortOrder: null || 0,
      multipleSelection: [],
      usrSearcher: "",
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        const apiService = new APIService(this.axios);
        const userDataObj = {
          userBucketIdList: null,
          companyBucketIdList: [this.getCompanyData.companyBucketId],
          searchPhrase: null,
          companyName: null,
          pageOptions: {
            skip: currPage - 1,
            take: 10,
            sortField: this.sorttingField,
            sortOrder: this.sortOrder,
          },
        };

        apiService.getUsersList(userDataObj).then((res) => {
          this.$store.dispatch(UserActionTypes.SET_COMPANY_USERS, res);
        });
      }
    },
    usrSearcher(newVal) {
      this.loading = true;
      const userDataObj = {
        userBucketIdList: null,
        companyBucketIdList: [this.getCompanyData.companyBucketId],
        searchPhrase: newVal || null,
        companyName: null,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      this.$store.dispatch(FlowActionTypes.SET_SEARCH_VALUE, newVal);
      this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, 1);
      const apiService = new APIService(this.axios);
      if (newVal.length !== 0) {
        this.checkSearchStr(userDataObj);
      } else {
        userDataObj.searchPhrase = null;
        apiService.getUsersList(userDataObj).then((res) => {
          this.$store.dispatch(UserActionTypes.SET_COMPANY_USERS, res);
          this.loading = false;
        });
      }
    },
  },
  methods: {
    checkSearchStr: _.debounce(function (this: any, dataobject: any) {
      const apiService = new APIService(this.axios);
      apiService.getUsersList(dataobject).then((res) => {
        this.$store.dispatch(UserActionTypes.SET_COMPANY_USERS, res);
        this.loading = false;
      });
    }, 1000),
    companyUsersData() {
      const apiService = new APIService(this.axios);
      const userDataObj = {
        userBucketIdList: null,
        companyBucketIdList: [this.getCompanyData.companyBucketId],
        searchPhrase: null,
        companyName: null,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_EMAILS, []);
      apiService.getUsersList(userDataObj).then((res) => {
        this.$store.dispatch(UserActionTypes.SET_COMPANY_USERS, res);
        this.loading = false;
      });
    },
    updatePaginationData() {
      this.currentPage =
        this.currentPage !== 0
          ? this.currentPage
          : this.getCompanyUsers.pagingData.currentPage;
      this.totalPages = this.getCompanyUsers.pagingData.totalPages;
      this.totalRowsCount = this.getCompanyUsers.pagingData.itemCount;
    },
    deleteUser() {
      const apiService = new APIService(this.axios);
      const emailData = {
        emails: this.getSlectedEmails,
      };
      apiService.deleteUsers(emailData).then((res) => {
        if (res.success) {
          const userDataObj = {
            userBucketIdList: null,
            companyBucketIdList: [this.getCompanyData.companyBucketId],
            searchPhrase: null,
            companyName: null,
            pageOptions: {
              skip: this.currentPage - 1,
              take: 10,
              sortField: null,
              sortOrder: null,
            },
          };

          apiService.getUsersList(userDataObj).then((resp) => {
            this.$store.dispatch(UserActionTypes.SET_COMPANY_USERS, resp);
          });
          this.$store.dispatch(FlowActionTypes.SET_SELECTED_EMAILS, []);
          this.showDeleteDailogue = false;
          ElMessage({
            message: "User has been deleted successfully.",
            type: "success",
          });
        } else {
          ElMessage.error(res.validationResult.validationMessages[0]);
        }
      });
    },
    handleSelectionChange(selected: any) {
      this.multipleSelection = selected;
      const selectedEmails: any[] = [];
      selected.map((item: any) => selectedEmails.push(item.email));
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_EMAILS, selectedEmails);
    },
    handleSorting({ prop, order }: { prop: any; order: any }) {
      // eslint-disable-next-line no-nested-ternary
      this.sorttingField =
        prop === "name"
          ? 1
          : prop === "email"
          ? 3
          : prop === "userStatus"
          ? 4
          : prop === "products"
          ? 5
          : 0;
      this.sortOrder = order === "ascending" ? 0 : 1;
      const userDataObj = {
        userBucketIdList: null,
        companyBucketIdList: [
          !_.isEmpty(this.getCompanyData)
            ? this.getCompanyData.companyBucketId
            : "",
        ],
        searchPhrase: null,
        companyName: null,
        pageOptions: {
          skip: this.currentPage - 1,
          take: 10,
          sortField: this.sorttingField,
          sortOrder: this.sortOrder,
        },
      };
      this.loading = true;
      const apiService = new APIService(this.axios);
      apiService.getUsersList(userDataObj).then((res) => {
        this.$store.dispatch(UserActionTypes.SET_COMPANY_USERS, res);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.companyUsersData();
  },
});
</script>
