import {
  MutationTree,
  ActionTree,
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
} from "vuex";
import { CompanyMutationTypes } from "./CompanyMutationTypes";
import { CompanyActionTypes } from "./CompanyActionTypes";
import { State, state } from "../AppState";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [CompanyActionTypes.SET_COMPANIES](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [CompanyActionTypes.SET_COMPANY_DATA](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [CompanyActionTypes.SET_AVAILABLE_LICADMINS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [CompanyActionTypes.SET_SHOW_ADDCOMPANYADMIN](
    context: AugmentedActionContext,
    show: boolean
  ): Promise<boolean>;
  [CompanyActionTypes.SET_LIC_USAGE](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [CompanyActionTypes.SET_COMPANY_USERCOUNT](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [CompanyActionTypes.SET_COMPANY_SUBSCRIPTIONS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
}

export const actions: ActionTree<State, State> = {
  [CompanyActionTypes.SET_COMPANIES](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(CompanyMutationTypes.UPDATE_COMPANIES, data);
  },
  [CompanyActionTypes.SET_COMPANY_DATA](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(CompanyMutationTypes.UPDATE_COMPANY_DATA, data);
  },
  [CompanyActionTypes.SET_SHOW_ADDCOMPANYADMIN](
    context: AugmentedActionContext,
    show: boolean
  ) {
    return context.commit(
      CompanyMutationTypes.UPDATE_SHOW_ADDCOMPANYADMIN,
      show
    );
  },
  [CompanyActionTypes.SET_AVAILABLE_LICADMINS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(
      CompanyMutationTypes.UPDATE_AVAILABLE_LICADMINS,
      data
    );
  },
  [CompanyActionTypes.SET_LIC_USAGE](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(CompanyMutationTypes.UPDATE_LIC_USAGE, data);
  },
  [CompanyActionTypes.SET_COMPANY_USERCOUNT](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(CompanyMutationTypes.UPDATE_COMPANY_USERCOUNT, data);
  },
  [CompanyActionTypes.SET_COMPANY_SUBSCRIPTIONS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(
      CompanyMutationTypes.UPDATE_COMPANY_SUBSCRIPTIONS,
      data
    );
  },
};

export type Mutations<S = State> = {
  [CompanyMutationTypes.UPDATE_COMPANIES](state: S, data: any): void;
  [CompanyMutationTypes.UPDATE_COMPANY_DATA](state: S, data: any): void;
  [CompanyMutationTypes.UPDATE_SHOW_ADDCOMPANYADMIN](
    state: S,
    show: boolean
  ): void;
  [CompanyMutationTypes.UPDATE_AVAILABLE_LICADMINS](state: S, data: any): void;
  [CompanyMutationTypes.UPDATE_LIC_USAGE](state: S, data: any): void;
  [CompanyMutationTypes.UPDATE_COMPANY_USERCOUNT](state: S, data: any): void;
  [CompanyMutationTypes.UPDATE_COMPANY_SUBSCRIPTIONS](
    state: S,
    data: any
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CompanyMutationTypes.UPDATE_COMPANIES](state, data: any) {
    state.companies = data;
  },
  [CompanyMutationTypes.UPDATE_COMPANY_DATA](state, data: any) {
    state.companyData = data;
  },
  [CompanyMutationTypes.UPDATE_AVAILABLE_LICADMINS](state, data: any) {
    state.licAdmins = data;
  },
  [CompanyMutationTypes.UPDATE_LIC_USAGE](state, data: any) {
    state.licUsage = data;
  },
  [CompanyMutationTypes.UPDATE_SHOW_ADDCOMPANYADMIN](state, show: boolean) {
    state.showAddCompanyAdmin = show;
  },
  [CompanyMutationTypes.UPDATE_COMPANY_USERCOUNT](state, data: any) {
    state.compUserCount = data;
  },
  [CompanyMutationTypes.UPDATE_COMPANY_SUBSCRIPTIONS](state, data: any) {
    state.companySubscriptions = data;
  },
};
export type Getters = {
  getCompanies(state: State): any;
  getShowAddCompanyAdmin(state: State): boolean;
  getCompanyData(state: State): any;
  getCompUserCount(state: State): any;
  getLicAdmins(state: State): any;
  getLicUsage(state: State): any;
  getCompanySubscriptions(state: State): any;
};

export const getters: GetterTree<State, State> = {
  getCompanies: (state) => state.companies,
  getCompanyData: (state) => state.companyData,
  getLicAdmins: (state) => state.licAdmins,
  getShowAddCompanyAdmin: (state) => state.showAddCompanyAdmin,
  getLicUsage: (state) => state.licUsage,
  getCompUserCount: (state) => state.compUserCount,
  getCompanySubscriptions: (state) => state.companySubscriptions,
};

export type CompanyStore<S = State> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const companystore: Module<State, State> = {
  state,
  getters,
  mutations,
  actions,
};
