<template>
  <h4 class="modal-title" id="assignaddontitle">ASSIGN ADD-ON</h4>
  <div class="modalbody" v-if="taskType === 'assignmultiaddons'">
    <div class="datadiv bottommargin">
      <div class="selectedusersdiv">
        <label class="font-regular-18"
          >Selected users &#40; {{ usersSelectedCount }} &#41;</label
        >
      </div>
      <label class="headerfont"> Available products </label>
      <div class="overflowdiv topmargin">
        <el-table
          id="prodtoassigntable"
          v-loading="loading"
          :data="products"
          :border="true"
          style="width: 100%"
          :row-style="rowdatastyle"
          highlight-current-row
          @current-change="handleCurrentChange"
          :show-header="false"
          empty-text="No assignable products available"
        >
          <!-- :row-class-name="tableRowClassName" -->
          <el-table-column min-width="230">
            <template #default="props">
              <div class="prodnamediv">
                <img src="@/assets/icons/vcicon.svg" class="cellimage" />
                <div style="text-align: start">
                  <h6
                    class="font-bold"
                    style="font-size: 14px; line-height: 19.07px"
                  >
                    {{ props.row.productName }}
                  </h6>
                  <label>
                    Version: {{ props.row.majorVersionNumber }}.{{
                      props.row.minorVersionNumber
                    }}
                  </label>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <label class="headerfont topmargin" v-if="productSelected">
        Select available add-on
      </label>
      <div class="overflowdiv topmargin" v-if="productSelected">
        <el-table
          id="prodtoassigntable"
          v-loading="addonLoading"
          :data="addons"
          :border="true"
          style="width: 100%"
          :row-style="rowdatastyle"
          :row-class-name="tableRowClassName"
          @selection-change="handleSelectedAddOns"
          :show-header="false"
          empty-text="No assignable products available"
        >
          <!-- :row-class-name="tableRowClassName" -->
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column min-width="230">
            <template #default="props">
              <div class="prodnamediv">
                <img src="@/assets/icons/addonicon.svg" class="cellimage" />
                <div style="text-align: start">
                  <h6
                    class="font-bold"
                    style="font-size: 14px; line-height: 19.07px"
                  >
                    {{ props.row.productName }}
                  </h6>
                  <label>
                    Version: {{ props.row.majorVersionNumber }}.{{
                      props.row.minorVersionNumber
                    }}
                  </label>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="props">
              <div
                :class="
                  usersSelectedCount > props.row.seats
                    ? 'less-seats-label'
                    : 'font-bold-16'
                "
              >
                {{ props.row.seatsUsed }}&#47;{{ props.row.seats }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="buttonsdiv">
      <el-button
        id="assignaddonnext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextHandler"
        :disabled="!showNext"
      >
        Next
      </el-button>
      <el-button
        id="prodassigncancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>
  <div v-if="taskType === 'summary'" class="modalbody">
    <AddonSummaryModal
      :assignedProduct="selectedProduct"
      dataof="product"
      :selectedAddonList="selectedAddons"
    />
    <!-- <div v-if="showError" style="display: flex" class="maxitems">
      <img
        src="@/assets/icons/warnerror.svg"
        class="cellimage"
        style="margin-top: 10px"
      />
      <label
        class="errordiv"
        v-if="addUserError !== ''"
        style="padding: 1rem 0"
      >
        {{ addUserError }}</label
      >
    </div> -->
    <div class="buttonsdiv">
      <el-button
        round
        class="btnstyle ml-4"
        variant="warning"
        id="prodassingconfirm"
        @click="sendInvite"
        :disabled="!enableControls"
      >
        <i v-if="!enableControls" class="fa fa-refresh fa-spin"></i> Confirm
      </el-button>
      <el-button
        id="prodassigncancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>
  <!-- <div v-if="taskType === 'emailSent'" class="modalbody">
      <div class="innerModal">
        <img
          src="@/assets/icons/mailsent.svg"
          style="width: 70px; margin-bottom: 1rem"
        />
        <label class="labelbld">Notification email sent to: </label>
        <div class="usersdiv">
          <div
            v-for="(item, index) in selectedUsers"
            :key="index"
            style="display: flex"
            class="btm-1"
          >
            <p>
              {{ item.name }}<br />
              &#40;{{ item.email }} &#41;
            </p>
          </div>
        </div>
      </div>
      <div class="buttonsdiv">
        <el-button
          id="prodassigndone"
          round
          class="btnstyle"
          variant="warning"
          @click="cancelInvite"
          style="float: right"
        >
          Done</el-button
        >
      </div>
    </div> -->
</template>

<style lang="scss">
.selectedusersdiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { ProductInfomation } from "@/utils/AppInterfaces";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
// import AssignUsersModal from "./AssignUsersModal.vue";
import AddonSummaryModal from "./AddonSummaryModal.vue";

export default defineComponent({
  name: "AssignMultipleAddonsModal",
  components: {
    AddonSummaryModal,
  },
  computed: {
    ...mapGetters([
      "getAvailableProducts",
      "getUserData",
      "getSelectedUsers",
      "getTaskType",
      "getCompanyData",
      "getCurrentUser",
      "getAddonsList",
      "getSearchValue",
    ]),
    products(): any {
      return this.intersectionList;
    },
    usersSelectedCount(): number {
      return this.getSelectedUsers.length || 0;
    },
    addons(): any {
      if (
        this.productSelected &&
        this.getAddonsList.length === 0 &&
        this.addonLoading
      )
        this.getSubscriptionAvailableAddOns();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      else this.addonLoading = false;
      return this.getAddonsList;
    },
    selectedUsers(): any {
      return this.getSelectedUsers.length !== undefined
        ? this.getSelectedUsers
        : [this.getUserData];
    },
    taskType(): any {
      return this.getTaskType;
    },
    showNext(): any {
      return (
        !_.isEmpty(this.selectedProduct) || !_.isEmpty(this.selectedAddons)
      );
    },
  },
  data() {
    return {
      selectedProduct: {} as unknown as ProductInfomation,
      userdetails: this.userData,
      enableControls: true,
      currentRow: null as unknown as ProductInfomation,
      showError: false,
      addUserError: "",
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      productsLoaded: false,
      loading: true,
      productSelected: false,
      addonLoading: true,
      selectedAddons: [],
    };
  },
  methods: {
    tableRowClassName({ row }: { row: ProductInfomation }) {
      if (this.getSelectedUsers.length > row.seats) {
        return "disable-row";
      }
      return "";
    },
    getAvailableProductsList() {
      this.productsLoaded = false;
      this.loading = true;
      // this.usersSelectedCount = this.getSelectedUsers.length;
      let companyId = "";
      switch (this.$route.name) {
        case "account":
          companyId = this.getCurrentUser.result.companyBucketId;
          break;
        case "companies":
          companyId = this.getCompanyData.companyBucketId;
          break;
        case "user":
          companyId = this.getUserData.companyBucketId;
          break;
        default:
      }
      const apiService = new APIService(this.axios);
      apiService.getAvailableProductSubscriptions(companyId).then((res) => {
        this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, res);
        this.productsLoaded = true;
        this.loading = false;
      });
    },
    getSubscriptionAvailableAddOns() {
      const mainProductInfomation = {
        mainProductBucketId: this.selectedProduct.productBucketId,
        mainProductMajorVersion: this.selectedProduct.majorVersionNumber,
        mainProductMinorVersion: this.selectedProduct.minorVersionNumber,
        companyBucketIdList: [this.selectedProduct.companyBucketId],
      };
      const apiService = new APIService(this.axios);
      apiService
        .getSubscriptionAddonsList(mainProductInfomation)
        .then((res) => {
          this.$store.dispatch(ProductActionTypes.SET_ADDONS_LIST, res.items);
          this.productsLoaded = true;
          this.addonLoading = false;
        });
    },
    nextHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "summary");
    },
    nextClickHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "account");
    },
    handleCurrentChange(val: ProductInfomation) {
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, val);
      this.selectedProduct = val;
      this.productSelected = true;
      if (val.seats !== val.seatsUsed) {
        this.selectedProduct = val;
        this.currentRow = val;
      }
      return "";
    },
    handleSelectedAddOns(selected: any) {
      console.log("selected addons", selected);
      this.selectedAddons = selected;
    },
    sendInvite() {
      this.enableControls = false;
      const addOnsList: any[] = [];
      this.selectedAddons.map((item: any) => {
        addOnsList.push({
          productBucketId: item.productBucketId,
          major: item.majorVersionNumber,
          minor: item.minorVersionNumber,
        });
      });
      const apiService = new APIService(this.axios);
      const multipleRequestData: any[] = [];
      this.selectedUsers.map((user: any) => {
        const inviteObj = {
          companyBucketId: this.selectedProduct.companyBucketId,
          userBucketId: user.userBucketId,
          entitlementsToAssign: addOnsList,
        };
        multipleRequestData.push(inviteObj);
      });
      apiService.assignMultipleSubscription(multipleRequestData).then((res) => {
        if (res.success) {
          const userDataObj = {
            userBucketIdList: null,
            companyBucketIdList: null,
            searchPhrase: this.getSearchValue,
            companyName: null,
            pageOptions: {
              skip: 0,
              take: 10,
              sortField: null,
              sortOrder: null,
            },
          };
          apiService.getUsersList(userDataObj).then((res) => {
            this.$store.dispatch(UserActionTypes.SET_USERS, res);
            this.loading = false;
          });

          this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "emailSent");
        } else {
          this.showError = true;
          let errormessage = "";
          errormessage = res.validationResult.validationMessages.join(" ");
          this.showError = true;
          this.addUserError = errormessage;
        }
      });
      return !this.enableControls;
    },
    cancelInvite() {
      // this.selectedProduct = {} as ProductInfomation;
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, false);
      this.$store.dispatch(ProductActionTypes.SET_ADDONS_LIST, []);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_EMAILS, []);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      // this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, {
      //   items: [],
      // });
      // this.$store.dispatch(
      //   UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
      //   {}
      // );
    },
  },
  mounted() {
    this.getAvailableProductsList();
  },
  props: {
    intersectionList: {
      type: Array as PropType<ProductInfomation[]>,
      required: false,
    },
  },
});
</script>
